.VIpgJd-ZVi9od-ORHb-OEVmcd {
  left: 0;
  top: 0;
  height: 39px;
  width: 100%;
  z-index: 10000001;
  position: fixed;
  border: none;
  border-bottom: 1px solid #6b90da;
  margin: 0;
  box-shadow: 0 0 8px 1px #999;
}

.VIpgJd-ZVi9od-xl07Ob-OEVmcd {
  z-index: 10000002;
  border: none;
  position: fixed;
  box-shadow: 0 3px 8px 2px #999;
}

.VIpgJd-ZVi9od-SmfZ-OEVmcd {
  z-index: 10000000;
  border: none;
  margin: 0;
}

.goog-te-gadget {
  font-family: arial;
  font-size: 11px;
  color: #666;
  white-space: nowrap;
}

.goog-te-gadget img {
  vertical-align: middle;
  border: none;
}

.goog-te-gadget-simple {
  background-color: #fff;
  border-left: 1px solid #d5d5d5;
  border-top: 1px solid #9b9b9b;
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #d5d5d5;
  font-size: 10pt;
  display: inline-block;
  padding-top: 1px;
  padding-bottom: 2px;
  cursor: pointer;
}

.goog-te-gadget-icon {
  margin-left: 2px;
  margin-right: 2px;
  width: 19px;
  height: 19px;
  border: none;
  vertical-align: middle;
}

.goog-te-combo {
  margin-left: 4px;
  margin-right: 4px;
  vertical-align: baseline;
}

.goog-te-gadget .goog-te-combo {
  margin: 4px 0;
}

.VIpgJd-ZVi9od-l4eHX-hSRGPd,
.VIpgJd-ZVi9od-l4eHX-hSRGPd:link,
.VIpgJd-ZVi9od-l4eHX-hSRGPd:visited,
.VIpgJd-ZVi9od-l4eHX-hSRGPd:hover,
.VIpgJd-ZVi9od-l4eHX-hSRGPd:active {
  font-size: 12px;
  font-weight: bold;
  color: #444;
  text-decoration: none;
}

.VIpgJd-ZVi9od-ORHb .VIpgJd-ZVi9od-l4eHX-hSRGPd,
.VIpgJd-ZVi9od-TvD9Pc-hSRGPd {
  display: block;
  margin: 0 10px;
}

.VIpgJd-ZVi9od-ORHb .VIpgJd-ZVi9od-l4eHX-hSRGPd {
  padding-top: 2px;
  padding-left: 4px;
}

.goog-te-combo,
.VIpgJd-ZVi9od-ORHb *,
.VIpgJd-ZVi9od-SmfZ *,
.VIpgJd-ZVi9od-xl07Ob *,
.VIpgJd-ZVi9od-vH1Gmf *,
.VIpgJd-ZVi9od-l9xktf * {
  font-family: arial;
  font-size: 10pt;
}

.VIpgJd-ZVi9od-ORHb {
  margin: 0;
  background-color: #e4effb;
  overflow: hidden;
}

.VIpgJd-ZVi9od-ORHb img {
  border: none;
}

.VIpgJd-ZVi9od-ORHb-bN97Pc {
  color: #000;
}

.VIpgJd-ZVi9od-ORHb-bN97Pc img {
  vertical-align: middle;
}

.VIpgJd-ZVi9od-ORHb-Tswv1b {
  color: #666;
  vertical-align: top;
  margin-top: 0;
  font-size: 7pt;
}

.VIpgJd-ZVi9od-ORHb-KE6vqe {
  width: 8px;
}

.VIpgJd-ZVi9od-LgbsSe {
  border-color: #e7e7e7;
  border-style: none solid solid none;
  border-width: 0 1px 1px 0;
}

.VIpgJd-ZVi9od-LgbsSe div {
  border-color: #ccc #999 #999 #ccc;
  border-right: 1px solid #999;
  border-style: solid;
  border-width: 1px;
  height: 20px;
}

.VIpgJd-ZVi9od-LgbsSe button {
  background: transparent;
  border: none;
  cursor: pointer;
  height: 20px;
  overflow: hidden;
  margin: 0;
  vertical-align: top;
  white-space: nowrap;
}

.VIpgJd-ZVi9od-LgbsSe button:active {
  background: none repeat scroll 0 0 #ccc;
}

.VIpgJd-ZVi9od-SmfZ {
  margin: 0;
  background-color: #fff;
  white-space: nowrap;
}

.VIpgJd-ZVi9od-SmfZ-hSRGPd {
  text-decoration: none;
  font-weight: bold;
  font-size: 10pt;
  border: 1px outset #888;
  padding: 6px 10px;
  white-space: nowrap;
  position: absolute;
  left: 0;
  top: 0;
}

.VIpgJd-ZVi9od-SmfZ-hSRGPd img {
  margin-left: 2px;
  margin-right: 2px;
  width: 19px;
  height: 19px;
  border: none;
  vertical-align: middle;
}

.VIpgJd-ZVi9od-SmfZ-hSRGPd span {
  text-decoration: underline;
  margin-left: 2px;
  margin-right: 2px;
  vertical-align: middle;
}

.goog-te-float-top .VIpgJd-ZVi9od-SmfZ-hSRGPd {
  padding: 2px;
  border-top-width: 0;
}

.goog-te-float-bottom .VIpgJd-ZVi9od-SmfZ-hSRGPd {
  padding: 2px;
  border-bottom-width: 0;
}

.VIpgJd-ZVi9od-xl07Ob-lTBxed {
  text-decoration: none;
  color: #00c;
  white-space: nowrap;
  margin-left: 4px;
  margin-right: 4px;
}

.VIpgJd-ZVi9od-xl07Ob-lTBxed span {
  text-decoration: underline;
}

.VIpgJd-ZVi9od-xl07Ob-lTBxed img {
  margin-left: 2px;
  margin-right: 2px;
}

.goog-te-gadget-simple .VIpgJd-ZVi9od-xl07Ob-lTBxed {
  color: #000;
}

.goog-te-gadget-simple .VIpgJd-ZVi9od-xl07Ob-lTBxed span {
  text-decoration: none;
}

.VIpgJd-ZVi9od-xl07Ob {
  background-color: #fff;
  text-decoration: none;
  border: 2px solid #c3d9ff;
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  left: 0;
  top: 0;
}

.VIpgJd-ZVi9od-xl07Ob-ibnC6b {
  padding: 3px;
  text-decoration: none;
}

.VIpgJd-ZVi9od-xl07Ob-ibnC6b,
.VIpgJd-ZVi9od-xl07Ob-ibnC6b:link {
  color: #00c;
  background: #fff;
}

.VIpgJd-ZVi9od-xl07Ob-ibnC6b:visited {
  color: #551a8b;
}

.VIpgJd-ZVi9od-xl07Ob-ibnC6b:hover {
  background: #c3d9ff;
}

.VIpgJd-ZVi9od-xl07Ob-ibnC6b:active {
  color: #00c;
}

.VIpgJd-ZVi9od-vH1Gmf {
  background-color: #fff;
  text-decoration: none;
  border: 1px solid #6b90da;
  overflow: hidden;
  padding: 4px;
}

.VIpgJd-ZVi9od-vH1Gmf-KrhPNb {
  width: 16px;
}

.VIpgJd-ZVi9od-vH1Gmf-hgDUwe {
  margin: 6px 0;
  height: 1px;
  background-color: #aaa;
  overflow: hidden;
}

.VIpgJd-ZVi9od-vH1Gmf-ibnC6b div,
.VIpgJd-ZVi9od-vH1Gmf-ibnC6b-gk6SMd div {
  padding: 4px;
}

.VIpgJd-ZVi9od-vH1Gmf-ibnC6b .uDEFge {
  display: none;
}

.VIpgJd-ZVi9od-vH1Gmf-ibnC6b-gk6SMd .uDEFge {
  display: auto;
}

.VIpgJd-ZVi9od-vH1Gmf-ibnC6b-gk6SMd .fmcmS {
  padding-left: 4px;
  padding-right: 4px;
}

.VIpgJd-ZVi9od-vH1Gmf-ibnC6b,
.VIpgJd-ZVi9od-vH1Gmf-ibnC6b-gk6SMd {
  text-decoration: none;
}

.VIpgJd-ZVi9od-vH1Gmf-ibnC6b div,
.VIpgJd-ZVi9od-vH1Gmf-ibnC6b:link div,
.VIpgJd-ZVi9od-vH1Gmf-ibnC6b:visited div,
.VIpgJd-ZVi9od-vH1Gmf-ibnC6b:active div {
  color: #00c;
  background: #fff;
}

.VIpgJd-ZVi9od-vH1Gmf-ibnC6b:hover div {
  color: #fff;
  background: #36c;
}

.VIpgJd-ZVi9od-vH1Gmf-ibnC6b-gk6SMd div,
.VIpgJd-ZVi9od-vH1Gmf-ibnC6b-gk6SMd:link div,
.VIpgJd-ZVi9od-vH1Gmf-ibnC6b-gk6SMd:visited div,
.VIpgJd-ZVi9od-vH1Gmf-ibnC6b-gk6SMd:hover div,
.VIpgJd-ZVi9od-vH1Gmf-ibnC6b-gk6SMd:active div {
  color: #000;
  font-weight: bold;
}

.VIpgJd-ZVi9od-l9xktf {
  background-color: #fff;
  overflow: hidden;
  padding: 8px;
  border: none;
  border-radius: 10px;
}

.VIpgJd-ZVi9od-l9xktf-OEVmcd {
  background-color: #fff;
  border: 1px solid #6b90da;
  box-shadow: 0 3px 8px 2px #999;
  border-radius: 8px;
}

.VIpgJd-ZVi9od-l9xktf img {
  border: none;
}

.VIpgJd-ZVi9od-l9xktf-fmcmS {
  margin-top: 6px;
}

.VIpgJd-ZVi9od-l9xktf-VgwJlc {
  margin-top: 6px;
  white-space: nowrap;
}

.VIpgJd-ZVi9od-l9xktf-VgwJlc * {
  vertical-align: middle;
}

.VIpgJd-ZVi9od-l9xktf-VgwJlc .DUGJie {
  background-image: url(https://www&google.com/images/zippy_minus_sm.gif);
}

.VIpgJd-ZVi9od-l9xktf-VgwJlc .TdyTDe {
  background-image: url(https://www&google.com/images/zippy_plus_sm.gif);
}

.VIpgJd-ZVi9od-l9xktf-VgwJlc span {
  color: #00c;
  text-decoration: underline;
  cursor: pointer;
  margin: 0 4px;
}

.VIpgJd-ZVi9od-l9xktf-I9GLp {
  margin: 6px 0 0;
}

.VIpgJd-ZVi9od-l9xktf-I9GLp form {
  margin: 0;
}

.VIpgJd-ZVi9od-l9xktf-I9GLp form textarea {
  margin-bottom: 4px;
  width: 100%;
}

.VIpgJd-ZVi9od-l9xktf-yePe5c {
  margin: 6px 0 4px;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf {
  z-index: 1000;
  position: fixed;
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
  left: -1000px;
  top: -1000px;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  left: -14px;
  top: -14px;
}

.VIpgJd-ZVi9od-aZ2wEe-OiiCO {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 104px;
  height: 104px;
  border-radius: 50px;
  background: #fff
    url(https://www.gstatic.com/images/branding/product/2x/translate_24dp.png)
    50% 50% no-repeat;
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
  -webkit-transform: scale(0.4);
  transform: scale(0.4);
  opacity: 0;
}

.VIpgJd-ZVi9od-aZ2wEe-OiiCO-ti6hGc {
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  opacity: 1;
}

.VIpgJd-ZVi9od-aZ2wEe {
  margin: 2px 0 0 2px;
  -webkit-animation: spinner-rotator 1.4s linear infinite;
  animation: spinner-rotator 1.4s linear infinite;
}

@-webkit-keyframes spinner-rotator {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}

@keyframes spinner-rotator {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}

.VIpgJd-ZVi9od-aZ2wEe-Jt5cK {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  stroke: #4285f4;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-animation: spinner-dash 1.4s ease-in-out infinite;
  animation: spinner-dash 1.4s ease-in-out infinite;
}

@-webkit-keyframes spinner-dash {
  0% {
    stroke-dashoffset: 187;
  }

  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

@keyframes spinner-dash {
  0% {
    stroke-dashoffset: 187;
  }

  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

.VIpgJd-yAWNEb-L7lbkb html,
.VIpgJd-yAWNEb-L7lbkb body,
.VIpgJd-yAWNEb-L7lbkb div,
.VIpgJd-yAWNEb-L7lbkb span,
.VIpgJd-yAWNEb-L7lbkb iframe,
.VIpgJd-yAWNEb-L7lbkb h1,
.VIpgJd-yAWNEb-L7lbkb h2,
.VIpgJd-yAWNEb-L7lbkb h3,
.VIpgJd-yAWNEb-L7lbkb h4,
.VIpgJd-yAWNEb-L7lbkb h5,
.VIpgJd-yAWNEb-L7lbkb h6,
.VIpgJd-yAWNEb-L7lbkb p,
.VIpgJd-yAWNEb-L7lbkb a,
.VIpgJd-yAWNEb-L7lbkb img,
.VIpgJd-yAWNEb-L7lbkb ol,
.VIpgJd-yAWNEb-L7lbkb ul,
.VIpgJd-yAWNEb-L7lbkb li,
.VIpgJd-yAWNEb-L7lbkb table,
.VIpgJd-yAWNEb-L7lbkb form,
.VIpgJd-yAWNEb-L7lbkb tbody,
.VIpgJd-yAWNEb-L7lbkb tr,
.VIpgJd-yAWNEb-L7lbkb td {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  text-align: left;
  line-height: normal;
}

.VIpgJd-yAWNEb-L7lbkb ol,
.VIpgJd-yAWNEb-L7lbkb ul {
  list-style: none;
}

.VIpgJd-yAWNEb-L7lbkb table {
  border-collapse: collapse;
  border-spacing: 0;
}

.VIpgJd-yAWNEb-L7lbkb caption,
.VIpgJd-yAWNEb-L7lbkb th,
.VIpgJd-yAWNEb-L7lbkb td {
  text-align: left;
  font-weight: normal;
}

.VIpgJd-yAWNEb-L7lbkb input::-moz-focus-inner {
  border: 0;
}

div > .VIpgJd-yAWNEb-L7lbkb {
  padding: 10px 14px;
}

.VIpgJd-yAWNEb-L7lbkb {
  color: #222;
  background-color: #fff;
  border: 1px solid #eee;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  display: none;
  font-family: arial;
  font-size: 10pt;
  width: 420px;
  padding: 12px;
  position: absolute;
  z-index: 10000;
}

.VIpgJd-yAWNEb-L7lbkb .VIpgJd-yAWNEb-nVMfcd-fmcmS,
.VIpgJd-yAWNEb-yAWNEb-Vy2Aqc-pbTTYe {
  clear: both;
  font-size: 10pt;
  position: relative;
  text-align: justify;
  width: 100%;
}

.VIpgJd-yAWNEb-L7lbkb .VIpgJd-yAWNEb-r4nke {
  color: #999;
  font-family: arial, sans-serif;
  margin: 4px 0;
  text-align: left;
}

.VIpgJd-yAWNEb-L7lbkb .VIpgJd-yAWNEb-TvD9Pc-LgbsSe {
  display: none;
}

.VIpgJd-yAWNEb-L7lbkb .VIpgJd-yAWNEb-l4eHX {
  float: left;
  margin: 0;
}

.VIpgJd-yAWNEb-L7lbkb .VIpgJd-yAWNEb-Z0Arqf-PLDbbf {
  display: inline-block;
}

.VIpgJd-yAWNEb-L7lbkb .VIpgJd-yAWNEb-fw42Ze-Z0Arqf-haAclf {
  display: none;
  width: 100%;
}

.VIpgJd-yAWNEb-L7lbkb .VIpgJd-yAWNEb-Z0Arqf-H9tDt {
  margin-top: 20px;
}

.VIpgJd-yAWNEb-L7lbkb .VIpgJd-yAWNEb-LK5yu {
  float: left;
}

.VIpgJd-yAWNEb-L7lbkb .VIpgJd-yAWNEb-qwU8Me {
  float: right;
}

.VIpgJd-yAWNEb-L7lbkb .VIpgJd-yAWNEb-cGMI2b {
  min-height: 15px;
  position: relative;
  height: 1%;
}

.VIpgJd-yAWNEb-L7lbkb .VIpgJd-yAWNEb-jOfkMb-Ne3sFf {
  background: -webkit-linear-gradient(top, #29910d 0, #20af0e 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#29910d),
    to(#20af0e)
  );
  background: linear-gradient(top, #29910d 0, #20af0e 100%);
  background: #29910d;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  box-shadow: inset 0 2px 2px #1e6609;
  -moz-box-shadow: inset 0 2px 2px #1e6609;
  -webkit-box-shadow: inset 0 2px 2px #1e6609;
  color: white;
  font-size: 9pt;
  font-weight: bolder;
  margin-top: 12px;
  padding: 6px;
  text-shadow: 1px 1px 1px #1e6609;
}

.VIpgJd-yAWNEb-L7lbkb .VIpgJd-yAWNEb-Z0Arqf-hSRGPd {
  color: #15c;
  cursor: pointer;
  font-family: arial;
  font-size: 11px;
  margin-right: 15px;
  text-decoration: none;
}

.VIpgJd-yAWNEb-L7lbkb > textarea {
  font-family: arial;
  resize: vertical;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 1px;
  border: 1px solid #d9d9d9;
  border-top: 1px solid silver;
  font-size: 13px;
  height: auto;
  overflow-y: auto;
  padding: 1px;
}

.VIpgJd-yAWNEb-L7lbkb textarea:focus {
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3);
  border: 1px solid #4d90fe;
  outline: none;
}

.VIpgJd-yAWNEb-L7lbkb .VIpgJd-yAWNEb-Z0Arqf-IbE0S {
  margin-right: 10px;
}

.VIpgJd-yAWNEb-L7lbkb .VIpgJd-yAWNEb-L4Nn5e-I9GLp {
  min-height: 25px;
  vertical-align: middle;
  padding-top: 8px;
}

.VIpgJd-yAWNEb-L7lbkb .VIpgJd-yAWNEb-L4Nn5e-I9GLp .VIpgJd-yAWNEb-Z0Arqf-I9GLp {
  margin-bottom: 5px;
  margin-bottom: 0;
}

.VIpgJd-yAWNEb-L7lbkb
  .VIpgJd-yAWNEb-L4Nn5e-I9GLp
  .VIpgJd-yAWNEb-Z0Arqf-I9GLp
  input {
  display: inline-block;
  min-width: 54px;
  *min-width: 70px;
  border: 1px solid #dcdcdc;
  border: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  color: #444;
  font-size: 11px;
  font-weight: bold;
  height: 27px;
  outline: 0;
  padding: 0 8px;
  vertical-align: middle;
  line-height: 27px;
  margin: 0 16px 0 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
  background-color: #f5f5f5;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f5f5f5),
    to(#f1f1f1)
  );
  background-image: -webkit-linear-gradient(top, #f5f5f5, #f1f1f1);
  background-image: linear-gradient(top, #f5f5f5, #f1f1f1);
  -webkit-user-select: none;
  -moz-user-select: none;
  cursor: default;
}

.VIpgJd-yAWNEb-L7lbkb
  .VIpgJd-yAWNEb-L4Nn5e-I9GLp
  .VIpgJd-yAWNEb-Z0Arqf-I9GLp
  input:hover {
  border: 1px solid #c6c6c6;
  color: #222;
  -webkit-transition: all 0s;
  transition: all 0s;
  background-color: #f8f8f8;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f8f8f8),
    to(#f1f1f1)
  );
  background-image: -webkit-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: linear-gradient(top, #f8f8f8, #f1f1f1);
}

.VIpgJd-yAWNEb-L7lbkb
  .VIpgJd-yAWNEb-L4Nn5e-I9GLp
  .VIpgJd-yAWNEb-Z0Arqf-I9GLp
  input:active {
  border: 1px solid #c6c6c6;
  color: #333;
  background-color: #f6f6f6;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f6f6f6),
    to(#f1f1f1)
  );
  background-image: -webkit-linear-gradient(top, #f6f6f6, #f1f1f1);
  background-image: linear-gradient(top, #f6f6f6, #f1f1f1);
}

.VIpgJd-yAWNEb-L7lbkb
  .VIpgJd-yAWNEb-L4Nn5e-I9GLp
  .VIpgJd-yAWNEb-Z0Arqf-I9GLp
  input:focus
  .VIpgJd-yAWNEb-L7lbkb
  .VIpgJd-yAWNEb-L4Nn5e-I9GLp
  .VIpgJd-yAWNEb-Z0Arqf-I9GLp
  input.AHmuwe
  .VIpgJd-yAWNEb-L7lbkb
  .VIpgJd-yAWNEb-L4Nn5e-I9GLp
  .VIpgJd-yAWNEb-Z0Arqf-I9GLp
  input:active,
.VIpgJd-yAWNEb-L7lbkb
  .VIpgJd-yAWNEb-L4Nn5e-I9GLp
  .VIpgJd-yAWNEb-Z0Arqf-I9GLp
  input:focus:active {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  -moz-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);
}

.VIpgJd-yAWNEb-L7lbkb
  .VIpgJd-yAWNEb-L4Nn5e-I9GLp
  .VIpgJd-yAWNEb-Z0Arqf-I9GLp
  input:focus,
.VIpgJd-yAWNEb-L7lbkb
  .VIpgJd-yAWNEb-L4Nn5e-I9GLp
  .VIpgJd-yAWNEb-Z0Arqf-I9GLp
  input.AHmuwe {
  outline: none;
  border: 1px solid #4d90fe;
  z-index: 4 !important;
}

.VIpgJd-yAWNEb-L7lbkb
  .VIpgJd-yAWNEb-L4Nn5e-I9GLp
  .VIpgJd-yAWNEb-Z0Arqf-I9GLp
  input.gk6SMd {
  background-color: #eee;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#eee),
    to(#e0e0e0)
  );
  background-image: -webkit-linear-gradient(top, #eee, #e0e0e0);
  background-image: linear-gradient(top, #eee, #e0e0e0);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  color: #333;
}

.VIpgJd-yAWNEb-L7lbkb
  .VIpgJd-yAWNEb-L4Nn5e-I9GLp
  .VIpgJd-yAWNEb-Z0Arqf-I9GLp
  input
  .VIpgJd-yAWNEb-Z0Arqf-sFeBqf {
  color: white;
  border-color: #3079ed;
  background-color: #4d90fe;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#4d90fe),
    to(#4787ed)
  );
  background-image: -webkit-linear-gradient(top, #4d90fe, #4787ed);
  background-image: linear-gradient(top, #4d90fe, #4787ed);
}

.VIpgJd-yAWNEb-L7lbkb
  .VIpgJd-yAWNEb-L4Nn5e-I9GLp
  .VIpgJd-yAWNEb-Z0Arqf-I9GLp
  input
  .VIpgJd-yAWNEb-Z0Arqf-sFeBqf:hover
  .VIpgJd-yAWNEb-L7lbkb
  .VIpgJd-yAWNEb-L4Nn5e-I9GLp
  .VIpgJd-yAWNEb-Z0Arqf-I9GLp
  input
  .VIpgJd-yAWNEb-Z0Arqf-sFeBqf:focus,
.VIpgJd-yAWNEb-L7lbkb
  .VIpgJd-yAWNEb-L4Nn5e-I9GLp
  .VIpgJd-yAWNEb-Z0Arqf-I9GLp
  input
  .VIpgJd-yAWNEb-Z0Arqf-sFeBqf.AHmuwe
  .VIpgJd-yAWNEb-L7lbkb
  .VIpgJd-yAWNEb-L4Nn5e-I9GLp
  .VIpgJd-yAWNEb-Z0Arqf-I9GLp
  input
  .VIpgJd-yAWNEb-Z0Arqf-sFeBqf:active {
  border-color: #3079ed;
  background-color: #357ae8;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#4d90fe),
    to(#357ae8)
  );
  background-image: -webkit-linear-gradient(top, #4d90fe, #357ae8);
  background-image: linear-gradient(top, #4d90fe, #357ae8);
}

.VIpgJd-yAWNEb-L7lbkb
  .VIpgJd-yAWNEb-L4Nn5e-I9GLp
  .VIpgJd-yAWNEb-Z0Arqf-I9GLp
  input
  .VIpgJd-yAWNEb-Z0Arqf-sFeBqf:hover {
  box-shadow: inset 0 0 0 1px #fff, 0 1px 1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 0 1px #fff, 0 1px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 0 0 1px #fff, 0 1px 1px rgba(0, 0, 0, 0.1);
}

.VIpgJd-yAWNEb-L7lbkb
  .VIpgJd-yAWNEb-L4Nn5e-I9GLp
  .VIpgJd-yAWNEb-Z0Arqf-I9GLp
  input:focus,
.VIpgJd-yAWNEb-L7lbkb
  .VIpgJd-yAWNEb-L4Nn5e-I9GLp
  .VIpgJd-yAWNEb-Z0Arqf-I9GLp
  input.AHmuwe,
.VIpgJd-yAWNEb-L7lbkb
  .VIpgJd-yAWNEb-L4Nn5e-I9GLp
  .VIpgJd-yAWNEb-Z0Arqf-I9GLp
  input:active,
.VIpgJd-yAWNEb-L7lbkb
  .VIpgJd-yAWNEb-L4Nn5e-I9GLp
  .VIpgJd-yAWNEb-Z0Arqf-I9GLp
  input:hover,
.VIpgJd-yAWNEb-L7lbkb
  .VIpgJd-yAWNEb-L4Nn5e-I9GLp
  .VIpgJd-yAWNEb-Z0Arqf-I9GLp
  input
  .VIpgJd-yAWNEb-Z0Arqf-sFeBqf:focus,
.VIpgJd-yAWNEb-L7lbkb
  .VIpgJd-yAWNEb-L4Nn5e-I9GLp
  .VIpgJd-yAWNEb-Z0Arqf-I9GLp
  input
  .VIpgJd-yAWNEb-Z0Arqf-sFeBqf.AHmuwe,
.VIpgJd-yAWNEb-L7lbkb
  .VIpgJd-yAWNEb-L4Nn5e-I9GLp
  .VIpgJd-yAWNEb-Z0Arqf-I9GLp
  input
  .VIpgJd-yAWNEb-Z0Arqf-sFeBqf:active,
.VIpgJd-yAWNEb-L7lbkb
  .VIpgJd-yAWNEb-L4Nn5e-I9GLp
  .VIpgJd-yAWNEb-Z0Arqf-I9GLp
  input
  .VIpgJd-yAWNEb-Z0Arqf-sFeBqf:hover {
  border-color: #3079ed;
}

.VIpgJd-yAWNEb-L7lbkb .VIpgJd-yAWNEb-mrxPge {
  color: #999;
  font-family: arial, sans-serif;
}

.VIpgJd-yAWNEb-L7lbkb .VIpgJd-yAWNEb-IFdKyd-W0vJo-fmcmS {
  color: #999;
  font-size: 11px;
  font-family: arial, sans-serif;
  margin: 15px 0 5px;
}

.VIpgJd-yAWNEb-L7lbkb .VIpgJd-yAWNEb-IFdKyd-u0pjoe-fmcmS {
  color: #800;
  display: none;
  font-size: 9pt;
}

.VIpgJd-yAWNEb-VIpgJd-fmcmS-sn54Q {
  background-color: #c9d7f1;
  box-shadow: 2px 2px 4px #99a;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  position: relative;
}

.VIpgJd-yAWNEb-L7lbkb
  .VIpgJd-yAWNEb-IFdKyd-xl07Ob
  .VIpgJd-yAWNEb-VIpgJd-xl07Ob {
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px #99a;
  min-width: 0;
  outline: none;
  padding: 0;
  position: absolute;
  z-index: 2000;
}

.VIpgJd-yAWNEb-L7lbkb
  .VIpgJd-yAWNEb-IFdKyd-xl07Ob
  .VIpgJd-yAWNEb-VIpgJd-j7LFlb {
  cursor: pointer;
  padding: 2px 5px 5px;
  margin-right: 0;
  border-style: none;
}

.VIpgJd-yAWNEb-L7lbkb
  .VIpgJd-yAWNEb-IFdKyd-xl07Ob
  .VIpgJd-yAWNEb-VIpgJd-j7LFlb:hover {
  background: #ddd;
}

.VIpgJd-yAWNEb-L7lbkb
  .VIpgJd-yAWNEb-IFdKyd-xl07Ob
  .VIpgJd-yAWNEb-VIpgJd-j7LFlb
  h1 {
  font-size: 100%;
  font-weight: bold;
  margin: 4px 0;
}

.VIpgJd-yAWNEb-L7lbkb
  .VIpgJd-yAWNEb-IFdKyd-xl07Ob
  .VIpgJd-yAWNEb-VIpgJd-j7LFlb
  strong {
  color: #345aad;
}

.VIpgJd-yAWNEb-L7lbkb .VIpgJd-yAWNEb-VIpgJd-eKm5Fc-hFsbo {
  text-align: right;
  position: absolute;
  right: 0;
  left: auto;
}

.VIpgJd-yAWNEb-L7lbkb
  .VIpgJd-yAWNEb-VIpgJd-j7LFlb-SIsrTd
  .VIpgJd-yAWNEb-VIpgJd-eKm5Fc-hFsbo {
  text-align: left;
  position: absolute;
  left: 0;
  right: auto;
}

.VIpgJd-yAWNEb-L7lbkb .VIpgJd-yAWNEb-yAWNEb-Vy2Aqc-fmcmS,
.VIpgJd-yAWNEb-L7lbkb .VIpgJd-yAWNEb-TVLw9c-ppHlrf-sn54Q {
  background-color: #f1ea00;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.5) 3px 3px 4px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  color: #f1ea00;
  cursor: pointer;
  margin: -2px -2px -2px -3px;
  padding: 2px 2px 2px 3px;
  position: relative;
}

.VIpgJd-yAWNEb-L7lbkb .VIpgJd-yAWNEb-TVLw9c-ppHlrf-sn54Q {
  color: #222;
}

.VIpgJd-yAWNEb-L7lbkb .VIpgJd-yAWNEb-yAWNEb-Vy2Aqc-pbTTYe {
  color: white;
  position: absolute !important;
}

.VIpgJd-yAWNEb-L7lbkb .VIpgJd-yAWNEb-TVLw9c-ppHlrf,
.VIpgJd-yAWNEb-L7lbkb
  .VIpgJd-yAWNEb-TVLw9c-ppHlrf
  .VIpgJd-yAWNEb-TVLw9c-ppHlrf-sn54Q {
  background-color: #c9d7f1;
  border-radius: 4px 4px 0 0;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  box-shadow: rgba(0, 0, 0, 0.5) 3px 3px 4px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  cursor: pointer;
  margin: -2px -2px -2px -3px;
  padding: 2px 2px 3px 3px;
  position: relative;
}

.VIpgJd-yAWNEb-L7lbkb span:focus {
  outline: none;
}

.VIpgJd-yAWNEb-L7lbkb .VIpgJd-yAWNEb-TVLw9c-DyVDA {
  background-color: transparent;
  border: 1px solid #4d90fe;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  margin: -2px;
  padding: 1px;
}

.VIpgJd-yAWNEb-L7lbkb .VIpgJd-yAWNEb-yAWNEb-TVLw9c-sn54Q-LzX3ef {
  border-left: 2px solid red;
  margin-left: -2px;
}

.VIpgJd-yAWNEb-L7lbkb .VIpgJd-yAWNEb-yAWNEb-TVLw9c-sn54Q-YIAiIb {
  border-right: 2px solid red;
  margin-right: -2px;
}

.VIpgJd-yAWNEb-L7lbkb .VIpgJd-yAWNEb-IFdKyd-YPqjbf {
  padding: 2px;
}

.VIpgJd-yAWNEb-L7lbkb .VIpgJd-yAWNEb-IFdKyd-YPqjbf-fmcmS {
  font-size: 11px;
  padding: 2px 2px 3px;
  margin: 0;
  background-color: #fff;
  color: #333;
  border: 1px solid #d9d9d9;
  border-top: 1px solid #c0c0c0;
  display: inline-block;
  vertical-align: top;
  height: 21px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-border-radius: 1px;
}

.VIpgJd-yAWNEb-L7lbkb .VIpgJd-yAWNEb-IFdKyd-YPqjbf-fmcmS:hover {
  border: 1px solid #b9b9b9;
  border-top: 1px solid #a0a0a0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.VIpgJd-yAWNEb-L7lbkb .VIpgJd-yAWNEb-IFdKyd-YPqjbf-fmcmS:focus {
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3);
  outline: none;
  border: 1px solid #4d90fe;
}

.VIpgJd-yAWNEb-L7lbkb .VIpgJd-yAWNEb-IFdKyd-YPqjbf-sFeBqf {
  font-size: 11px;
  padding: 2px 6px 3px;
  margin: 0 0 0 2px;
  height: 21px;
}

.VIpgJd-yAWNEb-hvhgNd {
  font-family: 'Google Sans', Arial, sans-serif;
}

.VIpgJd-yAWNEb-hvhgNd .VIpgJd-yAWNEb-hvhgNd-l4eHX-i3jM8c {
  position: absolute;
  top: 10px;
  left: 14px;
}

.VIpgJd-yAWNEb-hvhgNd .VIpgJd-yAWNEb-hvhgNd-l4eHX-SIsrTd {
  position: absolute;
  top: 10px;
  right: 14px;
}

.VIpgJd-yAWNEb-hvhgNd .VIpgJd-yAWNEb-hvhgNd-k77Iif-i3jM8c,
.VIpgJd-yAWNEb-hvhgNd .VIpgJd-yAWNEb-hvhgNd-k77Iif-SIsrTd {
  margin: 16px;
  padding: 0;
}

.VIpgJd-yAWNEb-hvhgNd .VIpgJd-yAWNEb-hvhgNd-IuizWc {
  margin: 0 0 0 36px;
  padding: 0;
  color: #747775;
  font-size: 14px;
  font-weight: 500;
}

.VIpgJd-yAWNEb-hvhgNd
  .VIpgJd-yAWNEb-hvhgNd-k77Iif-SIsrTd
  .VIpgJd-yAWNEb-hvhgNd-IuizWc {
  text-align: right;
  margin: 0 36px 0 0;
}

.VIpgJd-yAWNEb-hvhgNd .VIpgJd-yAWNEb-nVMfcd-fmcmS .VIpgJd-yAWNEb-hvhgNd-axAV1 {
  width: auto;
  padding: 12px 0 0;
  color: #1f1f1f;
  font-size: 16px;
  text-align: initial;
}

.VIpgJd-yAWNEb-hvhgNd
  .VIpgJd-yAWNEb-nVMfcd-fmcmS
  .VIpgJd-yAWNEb-hvhgNd-axAV1
  .VIpgJd-yAWNEb-SIsrTd {
  text-align: right;
}

.VIpgJd-yAWNEb-hvhgNd .VIpgJd-yAWNEb-hvhgNd-N7Eqid {
  border-radius: 0 0 12px 12px;
  margin: 0;
  background: #f1f4f9;
  position: relative;
  min-height: 50px;
}

.VIpgJd-yAWNEb-hvhgNd .VIpgJd-yAWNEb-hvhgNd-N7Eqid .VIpgJd-yAWNEb-SIsrTd {
  text-align: right;
}

.VIpgJd-yAWNEb-hvhgNd .VIpgJd-yAWNEb-hvhgNd-N7Eqid-B7I4Od {
  display: inline-block;
  width: 77%;
  padding: 12px;
}

.VIpgJd-yAWNEb-hvhgNd
  .VIpgJd-yAWNEb-hvhgNd-N7Eqid-B7I4Od
  .VIpgJd-yAWNEb-SIsrTd {
  text-align: right;
}

.VIpgJd-yAWNEb-hvhgNd .VIpgJd-yAWNEb-hvhgNd-UTujCb {
  color: #1f1f1f;
  font-size: 12px;
  font-weight: 500;
}

.VIpgJd-yAWNEb-hvhgNd
  .VIpgJd-yAWNEb-hvhgNd-N7Eqid-B7I4Od
  .VIpgJd-yAWNEb-SIsrTd
  .VIpgJd-yAWNEb-hvhgNd-UTujCb {
  text-align: right;
}

.VIpgJd-yAWNEb-hvhgNd .VIpgJd-yAWNEb-hvhgNd-eO9mKe {
  color: #444746;
  font-size: 12px;
  padding-top: 4px;
}

.VIpgJd-yAWNEb-hvhgNd
  .VIpgJd-yAWNEb-hvhgNd-N7Eqid-B7I4Od
  .VIpgJd-yAWNEb-SIsrTd
  .VIpgJd-yAWNEb-hvhgNd-eO9mKe {
  text-align: right;
}

.VIpgJd-yAWNEb-hvhgNd .VIpgJd-yAWNEb-hvhgNd-xgov5 {
  position: absolute;
  top: 10px;
  right: 5px;
}

.VIpgJd-yAWNEb-hvhgNd .VIpgJd-yAWNEb-hvhgNd-xgov5 .VIpgJd-yAWNEb-SIsrTd {
  left: 5px;
  right: auto;
}

.VIpgJd-yAWNEb-hvhgNd .VIpgJd-yAWNEb-hvhgNd-THI6Vb {
  fill: .goog-gt-vt .goog-gt-vt-thumbicon 0b57d0;
}

.VIpgJd-yAWNEb-hvhgNd .VIpgJd-yAWNEb-hvhgNd-bgm6sf {
  margin: -4px 2px 0 0;
  padding: 2px 0 0;
  width: 48px;
  height: 48px;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  background: none;
}

.VIpgJd-yAWNEb-hvhgNd .VIpgJd-yAWNEb-hvhgNd-bgm6sf:hover {
  background: #e8ebec;
}

.VIpgJd-yAWNEb-hvhgNd .VIpgJd-yAWNEb-hvhgNd-aXYTce {
  display: none;
}

.VIpgJd-yAWNEb-wdjgF {
  font-family: 'Google Sans', Arial, sans-serif;
}

.VIpgJd-yAWNEb-wdjgF .VIpgJd-yAWNEb-hvhgNd-l4eHX-i3jM8c {
  position: absolute;
  top: 10px;
  left: 10px;
}

.VIpgJd-yAWNEb-wdjgF .VIpgJd-yAWNEb-hvhgNd-l4eHX-SIsrTd {
  position: absolute;
  top: 10px;
  right: 10px;
}

.VIpgJd-yAWNEb-wdjgF .VIpgJd-yAWNEb-hvhgNd-k77Iif-i3jM8c {
  margin: 16px 16px 16px 48px;
  padding: 0;
}

.VIpgJd-yAWNEb-wdjgF .VIpgJd-yAWNEb-hvhgNd-k77Iif-SIsrTd {
  margin: 16px 48px 16px 16px;
  padding: 0;
}

.VIpgJd-yAWNEb-wdjgF .VIpgJd-yAWNEb-hvhgNd-IuizWc {
  margin: 0;
  padding: 0;
  color: #1f1f1f;
  font-size: 14px;
  font-weight: 500;
}

.VIpgJd-yAWNEb-wdjgF
  .VIpgJd-yAWNEb-hvhgNd-k77Iif-SIsrTd
  .VIpgJd-yAWNEb-hvhgNd-IuizWc {
  text-align: right;
}

.VIpgJd-yAWNEb-wdjgF .VIpgJd-yAWNEb-nVMfcd-fmcmS .VIpgJd-yAWNEb-hvhgNd-axAV1 {
  width: auto;
  padding: 8px 0 6px;
  color: #1f1f1f;
  font-size: 16px;
  text-align: initial;
}

.VIpgJd-yAWNEb-wdjgF
  .VIpgJd-yAWNEb-nVMfcd-fmcmS
  .VIpgJd-yAWNEb-hvhgNd-axAV1
  .VIpgJd-yAWNEb-SIsrTd {
  text-align: right;
}

.VIpgJd-yAWNEb-wdjgF .VIpgJd-yAWNEb-hvhgNd-fAt1Sb {
  height: 0;
  width: 70%;
  border: none;
  border-top: 1px solid #c2e7ff;
  border-collapse: collapse;
  background: none;
}

.VIpgJd-yAWNEb-wdjgF .VIpgJd-yAWNEb-hvhgNd-yVJrbd {
  margin: 0;
  padding: 0;
  color: #0b57d0;
  font-size: 14px;
  font-weight: 500;
}

.VIpgJd-yAWNEb-wdjgF
  .VIpgJd-yAWNEb-hvhgNd-k77Iif-SIsrTd
  .VIpgJd-yAWNEb-hvhgNd-yVJrbd {
  text-align: right;
}

.VIpgJd-yAWNEb-wdjgF .VIpgJd-yAWNEb-hvhgNd-IkhkZd {
  width: auto;
  padding: 8px 0 0;
  color: #0b57d0;
  font-size: 16px;
  text-align: initial;
}

.VIpgJd-yAWNEb-wdjgF .VIpgJd-yAWNEb-hvhgNd-IkhkZd .VIpgJd-yAWNEb-SIsrTd {
  text-align: right;
}

.VIpgJd-yAWNEb-wdjgF .VIpgJd-yAWNEb-hvhgNd-N7Eqid {
  border-radius: 0 0 12px 12px;
  margin: 0;
  background: #f1f4f9;
  position: relative;
  min-height: 50px;
}

.VIpgJd-yAWNEb-wdjgF .VIpgJd-yAWNEb-hvhgNd-N7Eqid .VIpgJd-yAWNEb-SIsrTd {
  text-align: right;
}

.VIpgJd-yAWNEb-wdjgF .VIpgJd-yAWNEb-hvhgNd-N7Eqid-B7I4Od {
  display: inline-block;
  width: 77%;
  padding: 12px;
}

.VIpgJd-yAWNEb-wdjgF .VIpgJd-yAWNEb-hvhgNd-N7Eqid-B7I4Od .VIpgJd-yAWNEb-SIsrTd {
  text-align: right;
}

.VIpgJd-yAWNEb-wdjgF .VIpgJd-yAWNEb-hvhgNd-UTujCb {
  color: #1f1f1f;
  font-size: 12px;
  font-weight: 500;
}

.VIpgJd-yAWNEb-wdjgF
  .VIpgJd-yAWNEb-hvhgNd-N7Eqid-B7I4Od
  .VIpgJd-yAWNEb-SIsrTd
  .VIpgJd-yAWNEb-hvhgNd-UTujCb {
  text-align: right;
}

.VIpgJd-yAWNEb-wdjgF .VIpgJd-yAWNEb-hvhgNd-eO9mKe {
  color: #444746;
  font-size: 12px;
  padding-top: 4px;
}

.VIpgJd-yAWNEb-wdjgF
  .VIpgJd-yAWNEb-hvhgNd-N7Eqid-B7I4Od
  .VIpgJd-yAWNEb-SIsrTd
  .VIpgJd-yAWNEb-hvhgNd-eO9mKe {
  text-align: right;
}

.VIpgJd-yAWNEb-wdjgF .VIpgJd-yAWNEb-hvhgNd-xgov5 {
  position: absolute;
  top: 10px;
  right: 5px;
}

.VIpgJd-yAWNEb-wdjgF .VIpgJd-yAWNEb-hvhgNd-xgov5 .VIpgJd-yAWNEb-SIsrTd {
  left: 5px;
  right: auto;
}

.VIpgJd-yAWNEb-wdjgF .VIpgJd-yAWNEb-hvhgNd-THI6Vb {
  fill: .goog-gt-tvt .goog-gt-vt-thumbicon 0b57d0;
}

.VIpgJd-yAWNEb-wdjgF .VIpgJd-yAWNEb-hvhgNd-bgm6sf {
  margin: -4px 2px 0 0;
  padding: 2px 0 0;
  width: 48px;
  height: 48px;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  background: none;
}

.VIpgJd-yAWNEb-wdjgF .VIpgJd-yAWNEb-hvhgNd-bgm6sf:hover {
  background: #e8ebec;
}

.VIpgJd-yAWNEb-wdjgF .VIpgJd-yAWNEb-hvhgNd-aXYTce {
  display: none;
}

sentinel {
}

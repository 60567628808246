@import url(../fonts/fonts.css);

body {
  margin: 0;
  font-family: 'Futura Md BT' !important;
  font-size: 14px !important;
}
#home {
  position: relative;
  display: flex;
  align-items: center;
}
section {
  /*height: 100vh;*/
}
.bg-white {
  background: #ffffff;
}
.bg_grey {
  background: #f3f3f3;
}
.font_gloria {
  font-family: 'Gloria Hallelujah', cursive;
}
.fixed-top {
  justify-content: start;
  padding-top: 15px;
  padding-bottom: 15px;
}
.mloyal_tabs2 li {
  display: inline-flex;
  padding: 5px 0;
}
.subhed {
  color: #4993d0;
  font-size: 22px;
  margin: 0;
  margin-top: 33px;
  margin-bottom: 20px;
  font-weight: 400;
}
.add_bu {
  background: #4993d0;
  padding: 10px 20px;
  color: #fff;
  border-radius: 7px;
  display: block;
  text-align: center;
}
.custom_tabs li {
  display: inline-block;
}
.add_bu:hover {
  color: #fff;
  text-decoration: none;
}
.custom_tabs li .active.add_bu {
  background: #155e9a;
}
.nav-tabs {
  border-bottom: 1px solid #dee2e6;
  margin: 39px auto 0;
}
.nav-tabs1 {
  border-bottom: 1px solid #dee2e6;
  margin: 30px auto;
  display: block;
  text-align: center;
}
.banner_para {
  margin: 0;
  font-style: italic;
}
.bg_white {
  background: #ffffff;
}
.product_suite_table {
  color: #737373;
}
.product_suite_table th,
.product_suite_table td {
  padding: 7px 15px;
  border: none;
  max-width: 250px;
}

/*CSS Added by Ajay Kumar on 061020*/

.navbar_brand {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #189ad6;
}
.navbar_brand h3 {
  margin: 0;
  font-size: 20px;
  display: none;
}
.country_select {
  position: relative;
}
.country_select select {
  border: none;
  background: none;
  -webkit-appearance: none;
  padding: 5px 15px 5px 10px;
}
.country_select:after {
  position: absolute;
  content: '';
  right: 5px;
  top: 10px;
  border-right: 1px solid #545454;
  border-bottom: 1px solid #545454;
  width: 7px;
  height: 7px;
  transform: rotate(45deg);
  pointer-events: none;
}
.gc_add_cart_btn {
  height: 43px;
  width: 130px;
  left: 0;
}
.cart_qty {
  display: flex;
  align-items: center;
  right: 0;
  left: 0;
  justify-content: space-around;
}
.spinner_button {
  background: #fff;
  border: none;
  padding: 8px 15px;
  border-radius: 2px;
  color: #daaf60;
  position: relative;
  font-size: 18px;
  width: 45px;
}
.cart_qty input[type='number'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
.cart_qty input {
  border: none;
  width: 40px;
  text-align: center;
  font-weight: 700;
  height: 43px;
  background: transparent;
}
.add_cart_btn {
  background: #daaf60;
  border: none;
  padding: 8px 15px;
  border-radius: 2px;
  color: #fff;
  width: 100%;
  position: absolute;
  font-size: 16px;
  float: right;
  display: flex;
  justify-content: center;
  top: 0;
}

.spinner_button:focus,
.add_cart_btn:focus {
  outline: none;
}
.add_cart_btn .plus_sign {
  position: absolute;
  right: 0;
  top: 0;
  background: #b03869;
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 24px;
}
.term_text {
  white-space: nowrap;
}
.viewAll {
  border: 1px solid #525252;
  padding: 5px 7px;
  border-radius: 6px;
  color: #e02080;
  text-transform: uppercase;
}

.product_block {
  width: 24%;
  margin-bottom: 30px;
}

.product_block_inner {
  position: relative;
  border: 1px solid #676767;
  padding: 15px 15px 25px;
}

.product_price {
  font-weight: 600;
}

.list_price {
  font-size: 12px;
  font-weight: 400;
  margin-left: 10px;
  position: relative;
}

.list_price:after {
  position: absolute;
  content: '';
  top: 8px;
  left: 0;
  background: #d15385;
  width: 100%;
  transform: rotate(-10deg);
  height: 0.5px;
}
.upi_tnc ul {
  padding: 10px;
  margin: 0;
}
.upi_tnc ul li {
  padding: 5px 0;
  font-size: 16px;
}
.product_name {
  font-size: 18px;
  color: #676767;
}

.product_qty {
  font-size: 18px;
  color: #676767;
}
.category_block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #777;
  border-radius: 10px;
  padding: 7px 10px;
  margin-bottom: 15px;
}
.category_block:hover {
  text-decoration: none;
}
.category_block h3 {
  font-size: 16px;
  margin: 0;
  color: #797979;
}
.cat_icon {
  max-width: 75px;
  border-radius: 50%;
}
.order_steps {
  background: #d15385;
}
.step_content {
  text-align: center;
  color: #fff;
  padding: 15px;
}
.testimonial_img {
  max-width: 90px;
}
.testimonial_content {
  margin-left: 30px;
  padding: 20px;
  background: #dddddd;
  border-radius: 10px;
  position: relative;
}

.testimonial_content:before {
  position: absolute;
  content: '';
  left: -39px;
  top: 30%;
  border: 20px solid;
  border-color: transparent #dddddd transparent transparent;
}

.owl-theme .owl-dots .owl-dot span {
  background: #b7b7b7 !important;
  width: 12px !important;
  height: 12px !important;
}
.owl-theme .owl-dots .owl-dot.active span {
  background: #535353 !important;
}
.corporate_customer {
  background: #383838;
}

.c_block {
  background: #fff;
  padding: 20px;
  text-align: center;
  margin-bottom: 15px;
}
.heading3 {
  font-size: 24px;
}
.c_block img {
  max-width: 50%;
  margin: 0 auto;
  display: block;
}

.c_block h4 {
  margin-top: 20px;
  font-size: 16px;
}

.btn_primary {
  background: #e57200;
  color: #fff;
  margin: 0 auto;
  padding: 8px 15px;
  display: block;
  border-radius: 0;
  width: 100%;
  font-weight: 600;
  font-size: 18px;
}

.btn_primary:hover,
.btn_primary:focus {
  outline: none;
  text-decoration: none;
  color: #fff;
}
.copy-right {
  padding: 0;
}

.powered_logo {
  max-width: 100px;
  float: right;
}

/*CSS Added by Ajay Kumar on 061020*/

.thead_custom {
  background: #4993d0;
  color: #ffffff;
}
.table_heading_main {
  padding: 10px 15px !important;
  font-weight: 600;
  font-size: 18px;
  color: #000;
}
.table_heading_main1 {
  background: #e6f0f9;
  color: #4993d0;
  font-weight: 600;
}
.table_footer {
  background: #66a3d9;
  color: #fff;
}
.thead_custom th {
  padding: 15px;
  border: none !important;
  font-weight: 500;
  font-size: 18px;
}
.table_label {
  font-style: italic;
}
span.yes {
  color: #049222;
}
span.no {
  color: #f00;
}
.im {
  background: #fff;
  padding: 33px 10px;
}
.benefits_ur p {
  color: #155e9a;
  margin: 10px 0;
}
.image {
  display: block;
  width: 100%;
  height: auto;
}
.offer_btn {
  border: none;
  padding: 10px 15px;
  background: #ffc80b;
  text-transform: uppercase;
  margin: 10px;
}

.text {
  color: #fff;
  font-size: 20px;
  border-radius: 15px;
  padding: 10px 20px;
  background: #4993d0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.text:hover {
  color: #fff;
  text-decoration: none;
}
.sum {
  border: none;
  border-radius: 4px;
  padding: 6px 20px;
}
.navbar-toggler {
  padding-left: 0;
  padding-right: 0;
}
.navbar-toggler,
.navbar-toggler:focus {
  border: none;
  outline: none;
}
.logo {
  /*margin-left: 10px;*/
  max-width: 160px;
  padding: 0;
  margin-right: 0;
}
.top_nav li a {
  font-weight: 600;
  padding: 7px 15px !important;
  color: #444444 !important;
}
.top_nav li.active a {
  color: #e02069 !important;
}
.thumb {
  display: block;
  position: relative;

  overflow: hidden;
}
a.thumb .thumb_overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.55);

  height: 100%;
  width: 100%;
  top: 0;
  transition: all 0.5s ease;
  opacity: 0;
}
a.thumb:hover .thumb_overlay {
  opacity: 1;
}
.add_btn,
.thumb_text {
  transition: all 0.2s ease;
}

a.thumb:hover .add_btn {
  top: 35%;
}
a.thumb:hover .thumb_text {
  bottom: 32%;
}
.add_btn {
  width: 50px;
  height: 50px;
  display: block;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  font-size: 42px;
  color: #000;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -25px;
}
.thumb_text {
  color: #fff;
  text-transform: uppercase;
  position: absolute;
  text-align: center;
  width: 100%;
  left: 0;
  bottom: 0;
}
.clients {
  /*background: url(../images/clients_bg.jpg) no-repeat center;*/
}
.banner_content {
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: center;
  background: #00000052;
}
.banner_content p {
  color: #868380;
  font-size: 18px;
  font-weight: 300;
}
.trust_para {
  font-size: 20px;
  font-weight: 500;
  color: #737373;
  margin: 0;
}
.heading1 {
  color: #4993d0;
  margin: 0;
  font-weight: 400;
  font-size: 1.75em;
}
.tc_thumb img {
  transition: all 0.5s ease;
}
.tc_thumb:hover img {
  transform: scale(0.9);
}
a.tc_thumb:hover .thumb_overlay:after {
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fab702+0,fa8802+100 */
  background: #fab702; /* Old browsers */
  background: -moz-linear-gradient(
    117deg,
    #fab702 0%,
    #fa8802 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    117deg,
    #fab702 0%,
    #fa8802 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    117deg,
    #fab702 0%,
    #fa8802 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fab702', endColorstr='#fa8802',GradientType=0 ); /* IE6-9 */
  opacity: 0.8;
}
.social_links {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -10px;
}
.footer {
  background: #ffffff;
}
.copyright_text {
  font-size: 14px;
  margin: 0;
  font-weight: 600;
  color: #505050;
}
/*css added by Ajay Kumar on 261218*/
.main-slider {
  /*background: #8cc0ce;*/
}
.slide_heading {
  color: #ffffff;
  font-size: 2.2em;
  font-weight: 400;
  text-align: left;
}
.primary_btn {
  background: #003593;
  color: #ffffff !important;
  font-weight: 600;
  display: inline-block;
}
.primary_btn:focus {
  box-shadow: none;
}
.banner_btn {
  color: #ffffff;
  background: #003593 !important;
  font-weight: 500;
  display: inline-block;
  padding: 10px 15px;
  min-width: 200px;
  margin: 5px;
  border-radius: 0;
}
.banner_btn:hover {
  color: #ffffff;
  background: #003593 !important;
}
.banner_btn:focus {
  box-shadow: none;
}
.download_btn {
  background: transparent;
  color: #c4eaf4;
  border: 2px solid #c4eaf4;
  height: 38px;
  margin-left: 10px;
}
.primary_btn:hover {
  color: #547781;
}
.download_btn:hover {
  color: #c4eaf4;
}
.analytics-block {
  background: #f8f9fd;
}
.heading2 {
  color: #797979;
  font-size: 24px;
  margin: 0;
  font-weight: 400;
}
.sub_heading {
  color: #6b6b6b;
  margin: 0;
  font-weight: 400;
  font-size: 1.75em;
}
.benefits-block {
  background: #c7e2f5;
}
.heading_benefits {
  color: #4993d0;
  font-size: 30px;
  margin: 0;
  font-weight: 400;
}
.subheading_benefits {
  color: #5c787c;
  margin: 0;
  font-weight: 400;
}
.benefits {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 18px;
  color: #4993d0;
  font-weight: 500;
}
.benefits li {
  padding: 10px 0;
}
.review-block {
  padding: 25px;
  box-shadow: 0 0 5px 0px #00000029;

  position: relative;
  background: #ffffff;
  min-height: 350px;

  max-width: 650px;
  border-radius: 10px;
}
/*.review-block:after {
	top:98%;
	left: 50%;
	width: 40px;
	height: 50px;
	margin-left: -20px;
	background: url(../images/caret.png) no-repeat center;
	background-size: contain;
}*/
.review-block h4,
.review-block .designation {
  margin: 0;
}
.review-block h4 {
  font-size: 24px;
  color: #4993d0;
}
.review-block .designation {
  font-size: 16px;
  font-style: italic;
  color: #929292;
}
.review-text {
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 16px;
}
.testimonial_block {
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: center;
}
.review_logo {
  max-width: 150px;

  padding: 15px;
  border-radius: 50%;
  border: 1px solid #808080;
  margin-right: 20px;
  background: #fff;
}
.mobi_paytm_logo img {
  max-width: 240px;
}
.price_block {
  background: #fff;
  padding-bottom: 25px;
  width: 30%;
  box-shadow: 0 0 10px 2px #4993d06b;
}
.price_heading {
  background-color: #4993d0;
  margin-bottom: 7px;
  padding: 11px 0 8px;
}
.price_heading h3 {
  color: #fff;
  font-weight: 500;
  font-size: 22px;
  text-align: center;
}
.price_content {
  padding: 33px 30px 6px;
  text-align: left;
}
.price_quote_btn_wrapper {
  text-align: center;
}
.price_content ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.price_content ul li {
  position: relative;
  padding: 5px 0 5px 20px;
}
.price_content ul li:before {
  border-color: #4897cc;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  border-style: solid;
  border-width: 3px;
  content: '';
  left: 0;
  position: absolute;
  top: 15px;
}
.price_quote_btn_wrapper {
  padding: 15px 0;
}
.banner_btns {
  width: 100%;
  padding-bottom: 50px;
  padding-top: 10px;
}
.omni_block span {
  font-size: 22px;
  color: #808080;
}

.omni_block img {
  width: 35px;
  max-width: 35px;
  height: auto;
  margin-right: 5px;
}
.brand-slider33 img {
  border: 1px solid #e8e8e8;
  padding: 5px;
}
.omni_block {
  /* font-family: Viner Hand ITC;*/
  display: flex;
  align-items: center;
  justify-content: center;
}
.omni_block_inner {
  text-align: left;
  padding: 5px;
}
.mloyal_tabs {
  list-style: none;
  margin: 0;
  padding: 0;
}

.mloyal_tabs li {
  display: block;
  padding: 5px 0;
}

.mloyal_tabs li a {
  display: flex;
  padding: 5px 10px;
  background: #4993d0;
  color: #fff;
  border-radius: 4px;
  align-items: center;
  position: relative;
}
footer {
  background: #000;
  padding: 20px 10px 20px;
}
.tnc {
  margin: 0;
  padding: 0;
  padding-left: 15px;
}
.tnc li {
  color: #484848;
  font-size: 13px;
  padding: 3px 0;
}

.video_text {
  color: #ffffff;
}
.video_text h3 {
  font-size: 22px;
  margin-bottom: 20px;
}
.mloyal_tabs li a:hover {
  text-decoration: none;
}

.mloyal_tabs li a.active {
  background: #155e9a;
}
.mloyal_tabs li a img {
  margin-right: 5px;
}
.yt_video {
  min-height: 450px;
}

/*Home banner css*/

.curve_bg {
  z-index: 100;
  height: 100%;
  /* background: url(../images/banner_curves.png) no-repeat left bottom; */
  background-size: 100% 230px;
  right: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  bottom: 0;
}
.home_bg {
  /* background: url(../img/banner-1.jpg); */
  /*height: 80vh;*/
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  overflow: hidden;
}
.faqs {
  /* background: url(../img/faq_bg.png) no-repeat; */
}
.main-slider .owl-item {
  overflow: hidden;
}
.home_banner1 {
  /* background: url(../images/texture_bg1.png); */
  /* background-color:#ffc80b; */
  background-size: contain;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  padding: 0;
  right: 0;
  position: relative;
  justify-content: center;
}
/* .decoration {
  background: url(../images/decorating_img.png) no-repeat 10% top,
    url(../images/decorating_img.png) no-repeat 90% top;
  background-size: 90px auto, 90px auto;
} */
.banner_inner {
  /* background: url(../images/banner_inner_bg.png) no-repeat center; */
  /* padding: 70px; */
  /* background-size: contain; */
  /* height: 370px; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.home_banner2 {
  height: 80vh;
  /* background: url(../images/banner_img2.png) no-repeat left center; */
  background-size: 30%;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
}
.home_banner3 {
  height: 80vh;
  /* background: url(../images/banner_img3.png) no-repeat left center; */
  background-size: 30%;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
}
.home_banner4 {
  height: 80vh;
  /* background: url(../images/banner_img4.png) no-repeat left center; */
  background-size: 30%;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
}
.banner_text2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.banner_heading1,
.banner_heading2,
.banner_heading3 {
  /* margin: 0 !important; */
}
.banner_heading2 {
  font-weight: 600 !important;
  text-align: center;
  color: #e50c13;
  line-height: 1;
  font-size: 48px;
}
.banner_heading1 {
  font-size: 120px;
  font-weight: 700 !important;
  letter-spacing: 0;
  word-spacing: 5px;
  position: relative;
  text-align: center;
  margin-bottom: 0;
  line-height: 1;
}
.banner_heading1 span {
  /*font-size: 42px */
  color: #f00;
}
.banner_heading3 {
  font-size: 24px;
  line-height: 1;
  font-weight: 400 !important;
  letter-spacing: 0px;
  padding: 7px 15px;
  border-radius: 50px;
  text-align: center;
  margin-bottom: 0;
  color: #002749;
}
.animation {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  pointer-events: none;
}

.animate {
  height: 40px;
  width: 40px;
  left: 0%;
  bottom: 195px;
  border-radius: 25px;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  position: absolute;
}
.like {
  -webkit-animation-duration: 7000ms;
  -webkit-animation-name: 'like';
}
.love {
  -webkit-animation-duration: 7500ms;
  -webkit-animation-name: 'love';
}
.reward {
  -webkit-animation-duration: 8600ms;
  -webkit-animation-name: 'reward';
}
.like1 {
  -webkit-animation-duration: 6900ms;
  -webkit-animation-name: 'love';
}
.love1 {
  -webkit-animation-duration: 8300ms;

  -webkit-animation-name: 'reward';
}
.reward1 {
  -webkit-animation-duration: 6500ms;
  -webkit-animation-name: 'like';
}
.animate img {
  max-width: 100%;
}
/*CSS added by Ajay Kumar on 140920 */

nav {
  transition: all 300ms ease-in-out;
  background: #ffffff;
  transform: translateY(-64px);
  top: 64px;
}

.header_sticky {
  position: fixed;
  transform: translateY(0);
  top: 0;
  width: 100%;
  z-index: 1000;
  border-bottom: 1px solid #000;
}
.aids_type {
  background: #002e6b;
}
.accordion-light .card-header {
  padding: 7px 15px;
  border-bottom: 0;
  background-color: transparent;
  text-align: left;
}
.accordion > .card .card-header {
  margin-bottom: -1px;
}
.accordion-light .card-header h4 {
  position: relative;
  margin-bottom: 0;
}

.accordion-light .card-header button {
  display: block;
  padding: 1rem 2.8rem 1rem 0;
  border: 0;
  background-color: transparent;
  font-size: 16px;
  line-height: 1.1;
  color: #7c8180;
  text-align: left;
}
.accordion-light .card-header button:focus {
  outline: none;
}
.accordion-light .card-header button .toggle-icon {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -6px;
  width: 12px;
  height: 12px;
}
.accordion-light .card-header button .toggle-icon:before {
  top: 0;
  left: 50%;
  width: 2px;
  height: 100%;
  margin-left: -1px;
}
.accordion-light .card-header button .toggle-icon:after {
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  margin-top: -1px;
}
.accordion-light .card-header button .toggle-icon:before,
.accordion-light .card-header button .toggle-icon:after {
  content: '';
  position: absolute;
  background-color: #4a4a4a;
  transition: transform 0.25s ease-out;
}
.accordion-light .card-header button[aria-expanded='true'] .toggle-icon:before {
  transform: rotate(90deg);
}
.accordion-light .card-header button[aria-expanded='true'] .toggle-icon:after {
  transform: rotate(180deg);
}
.process_block {
  position: relative;
  padding: 10px;
}
.process_img {
  transition: all 200ms;
  overflow: hidden;
}
.process_block h3 {
  font-size: 18px;
  margin-top: 15px;
  text-align: center;
}
.process_block p {
  text-align: center;
}
.process_block2 > div {
  max-width: 130px;
  margin: 0 auto;
}
.under_green {
  position: relative;
}
/*.under_green:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 3px;
    background: #69a041;
    top: 110%;
    left: 0;
    border-radius: 25px;
}*/
.process_block2 h3 {
  font-size: 16px;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.type_img {
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
}

.list4 h3 {
  color: #fff;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 1px;
}

.contact_block {
  border-right: 1px dashed #e0e0e0;
  padding: 20px 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  min-height: 140px;
}
.contact_icon {
  max-width: 70px;
  margin-right: 20px;
}
.contact_cont h4 {
  font-size: 15px;
}
.contact_cont p {
  font-size: 13px;
  margin: 0;
}
.contact_cont a {
  font-size: 13px;
  color: #003592;
}
.footer_logo {
  max-width: 100px;
}
.footer_content {
  font-size: 13px;
}
.social_links li a {
  color: #003592;
  max-width: 50px;
  display: block;
  padding: 3px 15px 3px 0;
}
.social_links li a img {
  max-width: 100%;
}
.footer_links li {
  display: block;
  padding: 0 5px;
  color: #fff;
}
.footer_links li a {
  color: #ffffff;
  padding: 5px 0;
  display: inline-block;
  text-align: left;
  font-weight: 500;
  font-size: 16px;
}
.footer_links {
  margin: 0;
  padding: 0;

  flex-wrap: wrap;
  list-style: none;
}
.footer_heading {
  font-size: 18px;
  color: #ffffff;
  font-weight: 500;
  padding-bottom: 10px;
  margin-bottom: 0;
}
.footer_links2 {
  margin: 0;
  padding: 0;
}
.footer_links2 li a {
  display: block;
  color: #003592;
  padding: 0 10px;
}
.btn_about {
  display: block;
  width: 160px;
  background: #003592;
  color: #fff;
  padding: 10px;
  text-align: center;
}

.btn_about:hover {
  color: #fff;
  text-decoration: none;
}
.list4 {
  margin: 20px auto;
  padding: 20px;
}
.contact_cont {
  width: 75%;
}
.blog_section {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.blog_block {
  width: 30%;
  margin-bottom: 30px;
}

.blog_content {
  padding: 10px;
}

.blog_heading {
  font-size: 18px;
  color: #003592;
  margin-bottom: 10px;
}

.blog_br {
  font-size: 13px;
  line-height: 22px;
}

.blog_href {
  display: block;
  text-decoration: underline;
  color: #d00;
}

.hearing_slider .owl-prev {
  width: 15px;
  height: 100px;
  position: absolute;
  top: 40%;
  margin-left: -20px;
  display: block !important;
  border: 0px solid black;
}
.hearing_slider.owl-theme .owl-nav [class*='owl-'] {
  color: #003592;
  font-size: 48px;
  margin: 5px;
  padding: 4px 7px;
  background: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
  font-family: monospace;
  font-weight: 700;
}
.hearing_slider .owl-next {
  width: 15px;
  height: 100px;
  position: absolute;
  top: 40%;
  right: 0;
  display: block !important;
  border: 0px solid black;
}
.hearing_slider .owl-prev i,
.hearing_slider .owl-next i {
  transform: scale(1, 6);
  color: #ccc;
}

.hearing_slider .owl-next:hover,
.hearing_slider .owl-prev:hover {
  color: #003592 !important;
  background: none !important;
}
.sidemenu {
  background: #fff;
}
.sidemenu ul {
  list-style: none;
  margin: 0;
  padding: 20px;
}
.sidemenu ul li a {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row-reverse;
  color: #04184b;
  font-size: 14px;
  padding: 10px 5px;
  border-bottom: 1px solid #e2e2e2;
}
.sidemenu ul li a:hover {
  text-decoration: none;
}
.side_icon {
  max-width: 25px;
  margin-right: 10px;
}
.side_icon img {
}
.menu_btn {
  display: block;
  color: #000;
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  margin-right: 10px;
}

.menu_btn:hover {
  text-decoration: none;
  color: #000;
}
.upi_section {
  /* background: url(../images/texture_bg.png); */
  min-height: 260px;
}
.upi_heading {
  font-size: 30px;
  color: #000000;
}
.upi_block {
  max-width: 700px;
  margin: 0 auto;
  background: #f9f9f9;
  padding: 30px;
  border-radius: 20px;
  /*display: none*/
}
.upi_form {
  margin-bottom: 20px;
  display: none;
}
.upi_input {
  position: relative;
  width: 100%;
  /* font-style: italic; */
  margin-bottom: 15px;
  color: #212529;
}
.upi_input .upi_control {
  padding: 10px 25px;
  width: 100%;
  font-size: 18px;
  border: 1px solid #f2f2f2;
}
.upi_input .upi-control {
  padding: 4px;
  padding-left: 15px;
  /* padding: 3px; */
  padding-right: 0px;
  font-size: 18px;
  border: 1px solid #f2f2f2;
}
.upi_input p {
  text-align: left;
  font-size: 12px;
  line-height: 1.2;
  font-style: normal;
  font-weight: 500;
  top: 100%;
  margin-bottom: 0;
  margin-top: 5px;
}
.or_text {
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  background: #287fc2;
  width: 50px;
  height: 50px;
  justify-content: center;
  border-radius: 30px;
  margin: 15px auto;
}
.upi_control:focus {
  outline: none;
}
.gen_btn {
  display: block;
  background: #fcb026;
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 7px 25px;
  font-style: normal;
  font-size: 18px;
  border: none;
  margin: 20px auto;
  border-radius: 4px;
}
.gen_btn:hover,
.gen_btn:focus {
  color: #fff;
  text-decoration: none;
  outline: none;
}
.terms_link {
  color: #000;
  font-weight: 700;

  text-decoration: underline;
}
.terms_link:hover {
  color: #424242;
  text-decoration: none;
}
.greet_body {
  text-align: center;
}
.cashbackimg {
  position: absolute;
  max-width: 200px;
  top: -100px;
  z-index: 32;
  right: 40px;
}
.cashbackimg img {
  width: 100%;
  height: auto;
  display: block;
}
.journey {
  /* background: #6d438d url(../images/bg_journey.png) no-repeat center top; */
  background-size: contain;
}
.journe_heading {
  color: #ffc643;
  font-size: 39px;
  font-weight: 700;
  text-transform: uppercase;
}
.journey_section {
  display: flex;
  align-items: center;
}
.jounery_block {
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.arrow_down {
  transform: rotate(90deg);
}
.rtl {
  direction: rtl;
}
.arrow_icon {
  padding: 0 10px;
}
.greet_head {
  background: #e9b150;
}
.modal_logo {
  margin: 0 auto;
  display: block;
  max-width: 70px;
}
#welcomeModal .modal-dialog {
  height: 100%;
  display: flex;
  align-items: center;
}
.j_block_inner h5 {
  text-align: center;
  margin-top: 10px;
  color: #fff;
  font-size: 18px;
}
.social_block {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.playstore {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.playstore p {
  font-weight: 600;
  color: #505050;
  margin: 0;
  margin-right: 10px;
}
.playstore ul {
  display: inline-flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.playstore ul li {
  padding-right: 10px;
}
.playstore ul li a {
  display: block;
  max-width: 110px;
}

.playstore ul li a img {
  max-width: 100%;
}
.w_heading {
  width: 60%;
}
@-webkit-keyframes love {
  0% {
    -webkit-transform: translate(0, 0) translate(70px, -20px);
    transform: translate(0, 0) translate(70px, -20px);
  }
  20% {
    -webkit-transform: translate(70px, -20px) translate(170px, -70px);
    transform: translate(70px, -20px) translate(170px, -70px);
  }
  40% {
    -webkit-transform: translate(170px, -70px) translate(270px, -120px);
    transform: translate(170px, -70px) translate(270px, -120px);
  }
  60% {
    -webkit-transform: translate(270px, -120px) translate(370px, -170px);
    transform: translate(270px, -120px) translate(370px, -170px);
    opacity: 0.6;
  }
  80% {
    -webkit-transform: translate(370px, -170px) translate(470px, -220px);
    transform: translate(370px, -170px) translate(470px, -220px);
    opacity: 0.3;
  }
  100% {
    -webkit-transform: translate(470px, -220px) translate(570px, -270px);
    transform: translate(470px, -220px) translate(570px, -270px);
    opacity: 0;
  }
}

@-webkit-keyframes like {
  0% {
    -webkit-transform: translate(0, 0) translate(70px, -20px);
    transform: translate(0, 0) translate(70px, -20px);
  }
  20% {
    -webkit-transform: translate(70px, -20px) translate(190px, -90px);
    transform: translate(70px, -20px) translate(190px, -90px);
  }
  40% {
    -webkit-transform: translate(190px, -90px) translate(290px, -140px);
    transform: translate(190px, -90px) translate(290px, -140px);
  }
  60% {
    -webkit-transform: translate(290px, -140px) translate(390px, -190px);
    transform: translate(290px, -140px) translate(390px, -190px);
    opacity: 0.6;
  }
  80% {
    -webkit-transform: translate(390px, -190px) translate(490px, -240px);
    transform: translate(390px, -190px) translate(490px, -240px);
    opacity: 0.3;
  }
  100% {
    -webkit-transform: translate(490px, -240px) translate(590px, -290px);
    transform: translate(490px, -240px) translate(590px, -290px);
    opacity: 0;
  }
}
@-webkit-keyframes reward {
  0% {
    -webkit-transform: translate(0, 0) translate(70px, -20px);
    transform: translate(0, 0) translate(70px, -20px);
  }
  20% {
    -webkit-transform: translate(70px, -20px) translate(150px, -50px);
    transform: translate(70px, -20px) translate(150px, -50px);
  }
  40% {
    -webkit-transform: translate(150px, -50px) translate(250px, -100px);
    transform: translate(150px, -50px) translate(250px, -100px);
  }
  60% {
    -webkit-transform: translate(250px, -100px) translate(350px, -150px);
    transform: translate(250px, -100px) translate(350px, -150px);
    opacity: 0.6;
  }
  80% {
    -webkit-transform: translate(350px, -150px) translate(450px, -200px);
    transform: translate(350px, -150px) translate(450px, -200px);
    opacity: 0.3;
  }
  100% {
    -webkit-transform: translate(450px, -200px) translate(550px, -250px);
    transform: translate(450px, -200px) translate(550px, -250px);
    opacity: 0;
  }
}

.loader {
  width: 90px;
  height: 90px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 20;

  margin-left: -45px;
  margin-top: -45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  /* border: 1px solid #003366; */
}
.loader img {
  max-width: 55%;
}
.loader_inner {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px solid #003366;
  border-radius: 50%;
  border-left-color: transparent;
  animation: rotate 1000ms linear 20ms infinite forwards;
  display: none;
}
.product {
  width: 100%;
  display: block;
  margin-bottom: 30px;
}
.product a {
  background: #e6e6e6;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8c8b8b;
  font-size: 18px;
  border-radius: 4px;
}
.navbar-dark .navbar-toggler {
  color: rgb(209 83 133);
  font-size: 14px;
}
.main_nav {
  background: #fcb026;
  padding: 15px 0 !important;
}
.inner_nav {
  background: #ffffff;
  padding: 15px 0;
  position: relative;
  z-index: 10;
}

.main_block {
  max-width: 650px;
  margin: 0 auto;
}

.main_block_inner {
  background: #fafafa;
  padding: 20px 40px;
}

.left_back {
  max-width: 8px;
  margin-right: 15px;
}

.login_heading {
  margin: 0;
  font-size: 22px;
  font-weight: 600;
}

.login_input {
  position: relative;
  margin-top: 20px;
}

.login_input input {
  background: #fff;
  padding: 12px 15px;
  font-size: 14px;
  border-radius: 0;
}
.login_input input:focus {
  outline: none;
  box-shadow: none;
  border-color: #585858;
}

.login_select {
  position: relative;
}
.login_select:after {
  position: absolute;
  content: '';
  right: 15px;
  top: 25px;
  border-width: 7px;
  border-style: solid;
  border-color: #8e8e8e transparent transparent;
  pointer-events: none;
}
.login_input select {
  background: #ddd;
  padding: 15px 15px 15px;
  height: initial !important;
  -webkit-appearance: none;
  appearance: none;
}
.login_input select:focus {
  background: #ddd;
  outline: none;
  box-shadow: none;
  border-color: transparent;
}
.input_placeholder {
  position: absolute;
  left: 15px;
  top: 16px;
  color: #6f6f6f;
  font-size: 16px;
  transition: all 200ms;
}
.login_input input:focus + .input_placeholder {
  top: 6px;
  font-size: 12px;
}
.btn_arrow {
  max-width: 17px;
  margin-left: 10px;
}
.new_user_btn {
  position: absolute;
  right: 15px;
  top: 25px;
  color: #8b8d91;
  font-weight: 500;
}
.login_text a {
  color: #66686b;
  text-decoration: underline;
  font-weight: 500;
}
.acc_section {
  position: relative;
}

.acc_section {
}

.header_title {
  font-size: 20px;
  margin: 0;
  font-weight: 600;
}
.acc_btn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e8e1e1;
  border-radius: 50%;
  font-size: 18px;
  font-weight: 500;
}
.acc_btn:after {
  display: none;
}
.acc_btn:before {
  position: absolute;
  content: '';
  top: -10px;
  left: 10px;
  /* background: url(../images/crown_icon.png) no-repeat center; */
  width: 20px;
  height: 20px;
  background-size: contain;
}
.reward_section {
  padding: 20px;
  background: #34374a;
}
.r_points {
  margin: 0;
  color: #fff;
  font-weight: 600;
  line-height: 1;
}
.crown_icon {
  max-width: 20px;
  margin-left: 5px;
  margin-top: 5px;
}
.wall_heading {
  color: #8a8b8d;
  font-size: 16px;
}
.transaction_link {
  color: #fff !important;
  text-decoration: underline;
  display: block;
}
.dots {
  max-width: 12px;
  display: block;
}
.dots:after {
  display: none;
}
.filters {
  padding-top: 20px;
  padding-left: 20px;
  overflow: auto;
}
.filter_badge {
  margin-right: 15px;
}
.filter_icon {
  max-width: 20px;
  margin-right: 3px;
}
.filter_badge input {
  display: none;
}
.filter_badge span {
  padding: 5px 15px;
  display: block;
  border: 1px solid #ccc;
  border-radius: 6px;
  background: #fafafa;
  color: #000;
  position: relative;
}
.filter_badge input:checked + span {
  border-color: #daaf60;
  color: #daaf60;
}

.filter_badge input:checked + span:after {
  position: absolute;
  content: '+';
  top: -6px;
  right: -6px;
  background: #daaf60;
  color: #fff;
  width: 16px;
  height: 16px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transform: rotate(45deg);
}
.reward_wrapper {
  padding: 20px;
}
.point_icon {
  max-width: 13px;
  margin-left: 3px;
}
.r_name {
  font-weight: 600;
}
.rew_points {
  padding: 3px 5px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  border-radius: 3px;
  line-height: 1;
  background: #fff;
  width: 70px;
}
.reward_block {
  margin-bottom: 25px;
  display: block;
  color: #000;
}
.header_cart_btn {
  display: block;
  padding: 5px 10px;
  margin-right: 10px;
}
.add_reward_btn {
  position: relative;
  display: block;
}

.nav_btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  padding: 5px;
  font-weight: 600;
  color: #656565;
  border-radius: 2px;
  box-shadow: 0px 1px 0px 0px #d8d8d8;
}

.nav_btn img {
  max-width: 45px;
  margin-right: 5px;
}
.nav_btn:hover {
  text-decoration: none;
  color: #2ea64a;
}
.nav_btn.disabled {
  pointer-events: none;
  opacity: 0.7;
  cursor: not-allowed;
}
.grecaptcha-badge {
  visibility: hidden;
}
/*loader css*/

.loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #00000066;
  background-size: 95px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading:after {
  position: absolute;
  content: '';
  /* background: url(../images/loader.gif) no-repeat center #fff; */
  background-size: 70px;
  width: 110px;
  height: 110px;
  border-radius: 50%;
}
.error {
  position: absolute;
  left: 0;
  top: 102%;
  color: #ff5858;
  font-size: 12px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
